/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Avatar, Box, Flex, Heading, Link, Paragraph } from "theme-ui"
import { useSiteMetadata } from "../hooks/use-site-metadata"

const Bio = () => {
  const metadata = useSiteMetadata()

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = metadata?.author
  const social = metadata?.social

  return (
    <Box sx={{ py: "l", px: "l" }}>
      <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ mr: "m" }}>
          <Heading>{author.name}</Heading>
          <Paragraph sx={{ textAlign: "justify" }}>
            {author?.summary}
            {` `}
            <Link href={`https://twitter.com/${social?.twitter || ``}`}>
              Twitter
            </Link>
          </Paragraph>
        </Box>
        {/* <Avatar
          width={100}
          height={100}
          src="/assets/img/profile-pic.jpg"
          alt="Profile picture"
        /> */}
      </Flex>
    </Box>
  )
}

export default Bio
