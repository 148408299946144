/** @jsxImportSource theme-ui */

import * as React from "react"
import { graphql } from "gatsby"
import Bio from "../components/bio"
import Layout from "../components/layout"
import { IndexQuery } from "../../graphql-types"
import { Grid } from "theme-ui"
import ArticleCard from "../components/article-card"
import { useSiteMetadata } from "../hooks/use-site-metadata"

interface IBlogIndexProps {
  data: IndexQuery
  location: Location
}
const BlogIndex: React.FC<IBlogIndexProps> = ({ data, location }) => {
  const metadata = useSiteMetadata()
  const siteTitle = metadata?.title || `Title`
  const posts = data.blog.nodes

  if (posts.length === 0) {
    return (
      <Layout
        location={location}
        pageTitle={siteTitle}
        canonicalUrl={metadata.siteUrl}
      >
        {/* <Bio /> */}
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout
      location={location}
      pageTitle={siteTitle}
      canonicalUrl={metadata.siteUrl}
    >
      <Bio />
      <Grid
        sx={{ variant: "grids.articleGrid" }}
        gap={64}
        columns={[1, 1, 1, 2]}
      >
        {/* <ol style={{ listStyle: `none` }}> */}
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          const description = post.frontmatter.description || post.excerpt
          return (
            // <div>test</div>
            <ArticleCard
              image={(post.frontmatter as any).featuredImage as any}
              title={title}
              description={description}
              to={post.fields.slug}
              date={post.frontmatter.date}
              // readingTime={post.fields.readingTime.text}
              key={post.fields.slug}
            />
          )
          // return (
          //   <li key={post.fields.slug}>
          //     <article
          //       className="post-list-item"
          //       itemScope
          //       itemType="http://schema.org/Article"
          //     >
          //       <header>
          //         <h2>
          //           <RouteLink
          //             to={post.fields.slug}
          //             itemProp="url"
          //             sx={{ variant: "links.title" }}
          //           >
          //             <span itemProp="headline">{title}</span>
          //           </RouteLink>
          //         </h2>
          //         <small>{post.frontmatter.date}</small>
          //       </header>
          //       <section>
          //         <p
          //           dangerouslySetInnerHTML={{
          //             __html: post.frontmatter.description || post.excerpt,
          //           }}
          //           itemProp="description"
          //         />
          //       </section>
          //     </article>
          //   </li>
          // )
        })}
        {/* </ol> */}
      </Grid>
    </Layout>
    // </ThemeProvider>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query Index {
    blog: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/blog/" } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featuredImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
