/** @jsxImportSource theme-ui */

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import { AspectImage, Card, Flex, Heading, Image, Text } from "theme-ui"
import RouteLink from "./route-link"

interface ArticleCardProps {
  image: string
  title: string
  description: string
  to: string
  date: string
  readingTime?: string
}
const ArticleCard: React.FC<ArticleCardProps> = ({
  image,
  title,
  description,
  to,
  date,
  readingTime,
}) => {
  let img: any = getImage(image as any)
  if (image) {
    img = getImage(image as any)
  } else {
    img = "https://lorempixel.com/600/313/abstract/"
  }
  return (
    <RouteLink to={to} itemProp="url" sx={{ variant: "links.unstyled" }}>
      <Card
        sx={{
          variant: "cards.articleCard",
        }}
      >
        {image ? (
          <GatsbyImage
            image={img}
            alt="banner"
            sx={{ variant: "images.rounded" }}
          />
        ) : (
          <AspectImage
            ratio={1200 / 627}
            src={randomImage()}
            sx={{ variant: "images.rounded" }}
          />
        )}

        {/* <AspectImage
          ratio={1200 / 627}
          src={image}
          sx={{ variant: "images.rounded" }}
        /> */}
        <Heading sx={{ variant: "headings.articleHeading", mb: "s" }}>
          {title}
        </Heading>
        <Flex>
          <Text sx={{ fontSize: "s", mb: "s" }}>{date}</Text>
          {readingTime && (
            <Text sx={{ fontSize: "s", ml: "m" }}>{readingTime}</Text>
          )}
        </Flex>
        <Text>{description}</Text>
      </Card>
    </RouteLink>
  )
}

function randomImage() {
  const a = 600 + Math.floor(Math.random() * 50) - 25
  const b = 313 + Math.floor(Math.random() * 50) - 25
  return `https://lorempixel.com/${a}/${b}/abstract/`
}
export default ArticleCard
